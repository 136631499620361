<template>
  <div class="outer-wrapper">
    <div class="title-wrapper">
      <span>应急救援器材数据管理</span>
    </div>
    <div class="inner-wrapper">
      <div class="editor-wrapper">
        <Tabs>
          <TabPane label="现有应急救援器材数据" icon="ios-people">
            <query-table
              :columnsFormatData="columnsFormatData"
              askData="emergencyGoodsManage"
              :refreshData="update_count"
              main_width="750px"
            ></query-table>
          </TabPane>
        </Tabs>
      </div>
      <div class="preview-wrapper">
        <Tabs>
          <TabPane label="新增应急救援器材信息" icon="md-cloud-upload">
            <div class="form-wrapper">
              <Form
                ref="addItem"
                :model="newItem"
                :rules="ruleValidate"
                :label-width="150"
              >
                <FormItem label="请选择线路：" prop="line_code">
                  <Select  v-model="newItem.line_code" style="width: 260px">
                      <Option
                      v-for="item in activeOption.lineChoices"
                      :value="item.line_code"
                      :key="item.line_code"
                      >{{ item.line_name }}</Option
                    >
                  </Select>
                </FormItem>
                <FormItem label="所属公司：" prop="company">
                  <Input v-model="newItem.company" placeholder="" />
                </FormItem>
                <FormItem label="救援器材名称：" prop="name">
                  <Input v-model="newItem.name" placeholder=""/>
                </FormItem>
                <FormItem label="规格型号：" prop="model">
                  <Input v-model="newItem.model" placeholder="" />
                </FormItem>
                <FormItem label="用途：" prop="gusage">
                  <Input v-model="newItem.gusage" placeholder=""/>
                </FormItem>
                <FormItem label="数量：" prop="amount">
                  <InputNumber v-model="newItem.amount" ></InputNumber>
                </FormItem>
                <FormItem label="单位：" prop="unit">
                  <Input v-model="newItem.unit" placeholder=""/>
                </FormItem>
                <FormItem label="储备地点：" prop="position">
                  <Input v-model="newItem.position" placeholder=""/>
                </FormItem>
                <FormItem label="有效期：" prop="valid_date">
                  <Input v-model="newItem.valid_date" placeholder=""/>
                </FormItem>
                <FormItem label="检修维保日期：" prop="repair_date">
                  <Input v-model="newItem.repair_date" placeholder=""/>
                </FormItem>
                <FormItem label="责任部门：" prop="res_department">
                  <Input v-model="newItem.res_department" placeholder=""/>
                </FormItem>
                <FormItem label="责任人及电话：" prop="res_person">
                  <Input v-model="newItem.res_person" placeholder=""/>
                </FormItem>
                <FormItem>
                  <Button type="primary" @click="handleSubmit('addItem')"
                    >提交</Button
                  >
                  <Button
                    @click="handleReset('addItem')"
                    style="margin-left: 8px"
                    >重置</Button
                  >
                </FormItem>
              </Form>
              <Spin fix v-show="loadingStatus"></Spin>
            </div>
          </TabPane>
          <TabPane label="批量添加" icon="md-cloud-upload">
            <div class="form-wrapper">
              <div class="title-wrapper">
                <p>
                  请上传包含应急救援器材相关数据的excel文件：
                  <a href="/public/templates/EmergencyGoodsTemplate.xlsx"
                    >(模板文件下载)</a
                  >
                </p>
              </div>
              <file-upload
                target="mulityByExcel"
                :iurl="post_url_tail"
                @handleSuccess="()=>this.update_count++"
              ></file-upload>
            </div>
          </TabPane>
        </Tabs>
      </div>
    </div>

<Modal
      v-model="scModal"
      width="800"
      :mask-closable="false"
      @on-cancel="modal_cancel"
    >
      <p slot="header" class="sc-header">
        <Icon type="ios-list-box" />
        <span>应急救援器材信息修改</span>
      </p>
      <div class="sc-content-wrapper">
          <div class="form-wrapper">
              <Form
                ref="alterItem"
                :model="currentItem"
                :rules="ruleValidate"
                :label-width="150"
              >
                <FormItem label="请选择线路：" prop="line_code">
                  <Select  v-model="currentItem.line_code" style="width: 260px">
                      <Option
                      v-for="item in activeOption.lineChoices"
                      :value="item.line_code"
                      :key="item.line_code"
                      >{{ item.line_name }}</Option
                    >
                  </Select>
                </FormItem>
                <FormItem label="所属公司：" prop="company">
                  <Input v-model="currentItem.company" placeholder="" />
                </FormItem>
                <FormItem label="救援器材名称：" prop="name">
                  <Input v-model="currentItem.name" placeholder=""/>
                </FormItem>
                <FormItem label="规格型号：" prop="model">
                  <Input v-model="currentItem.model" placeholder="" />
                </FormItem>
                <FormItem label="用途：" prop="gusage">
                  <Input v-model="currentItem.gusage" placeholder=""/>
                </FormItem>
                <FormItem label="数量：" prop="amount">
                  <InputNumber v-model="currentItem.amount" ></InputNumber>
                </FormItem>
                <FormItem label="单位：" prop="unit">
                  <Input v-model="currentItem.unit" placeholder=""/>
                </FormItem>
                <FormItem label="储备地点：" prop="position">
                  <Input v-model="currentItem.position" placeholder=""/>
                </FormItem>
                <FormItem label="有效期：" prop="valid_date">
                  <Input v-model="currentItem.valid_date" placeholder=""/>
                </FormItem>
                <FormItem label="检修维保日期：" prop="repair_date">
                  <Input v-model="currentItem.repair_date" placeholder=""/>
                </FormItem>
                <FormItem label="责任部门：" prop="res_department">
                  <Input v-model="currentItem.res_department" placeholder=""/>
                </FormItem>
                <FormItem label="责任人及电话：" prop="res_person">
                  <Input v-model="currentItem.res_person" placeholder=""/>
                </FormItem>
              </Form>
          </div>
      </div>
      <div slot="footer">
        <Button size="large" @click="modal_save('alterItem')">确定</Button>
        <Button size="large" @click="modal_cancel">取消</Button>
      </div>
    </Modal>
  </div>
</template>
<script>
import fileUpload from "@/components/Common/FileUpload.vue";
import queryTable from "@/components/Common/QueryTable.vue";

let userToken = localStorage.getItem("current_user_token");

function itemFac() {
  return {
    id: 0,
    line_code:"",
    line_name:"",
    name:"",
    model:"",
    amount:null,
    unit:"",
    gusage:"",
    position:"",
    valid_date:'',
    repair_date:'',
    res_department:"",
    res_person:"",
    company:""
  };
}

export default {
  name: "emergencyGoodsManage",
  components: {
    queryTable,
    fileUpload,
  },
  props: {},
  data() {
    return {
      title: "应急救援物资",
      post_url_tail: "/emergency_goods_alter",
      columnsFormatData: [
        {
          title: "所属公司",
          key: "company",
          align: "center",
          width: 110,
        },
        {
          title: "线路",
          key: "line_name",
          align: "center",
          width: 75,
        },
        {
          title: "救援器材名称",
          key: "name",
          align: "center",
        },
        {
          title: "规格型号",
          key: "model",
          tooltip: "true",
          align: "center",
          width: 95,
        },
        {
          title: "储备地点",
          key: "position",
          tooltip: "true",
          align: "center",
          width: 90,
        },
        {
          title: "操作",
          align: "center",
          width: 130,
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    size: "small",
                  },
                  style: { color: "green" },
                  on: {
                    click: () => {
                      this.itemEdit(params.row);
                    },
                  },
                },
                "修改"
              ),
              h(
                "Button",
                {
                  props: {
                    size: "small",
                  },
                  style: { color: "red", marginLeft: "5px" },
                  on: {
                    click: () => {
                      this.confirmBox(params.row);
                    },
                  },
                },
                "删除"
              ),
            ]);
          },
        },
      ],
      ruleValidate: {
        line_code: [
          {
            required: true,
            type: "string",
            message: "请选择线路！",
            trigger: "blur",
          },
        ],
        company: [
          {
            required: true,
            type: "string",
            message: "请输入所属公司名称！",
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            type: "string",
            message: "请输入救援器材名称！",
            trigger: "blur",
          },
        ],
        model: [
          {
            required: true,
            type: "string",
            message: "请输入规格型号！",
            trigger: "blur",
          },
        ],
        gusage: [
          {
            required: true,
            type: "string",
            message: "请输入用途！",
            trigger: "blur",
          },
        ],
        amount: [
          {
            required: true,
            type: "number",
            message: "请输入数量！",
            trigger: "blur",
          },
        ],
        position: [
          {
            required: true,
            type: "string",
            message: "请输入储备地点！",
            trigger: "blur",
          },
        ],
        unit: [
          {
            required: true,
            type: "string",
            message: "请输入数量单位！",
            trigger: "blur",
          },
        ],
         valid_date: [
          {
            required: true,
            type: "string",
            message: "请输入有效期！",
            trigger: "blur",
          },
        ],
         repair_date: [
          {
            required: true,
            type: "string",
            message: "请输入检测维保日期！",
            trigger: "blur",
          },
        ],
         res_department: [
          {
            required: true,
            type: "string",
            message: "请输入责任部门！",
            trigger: "blur",
          },
        ],
        res_person: [
          {
            required: true,
            type: "string",
            message: "请输入责任人及电话！",
            trigger: "blur",
          },
        ],
      },
      option: {},
      activeOption: {},
      update_count: 0,
      loadingStatus: false,
      newItem: itemFac(),
      currentItem: itemFac(),
      scModal: false,
    };
  },
  watch: {},
  computed: {},
  created() {
    // this.getStaticOption();
    this.getActiveOption();
  },
  methods: {
    // getStaticOption() {
    //   this.$axios
    //     .post(this.$url + "/static_option", {
    //       userToken: userToken,
    //       askData: "resultRiskManage",
    //     })
    //     .then((response) => {
    //         this.option = response.data.res_record;
    //     });
    // },
    getActiveOption() {
      this.$axios
        .post(this.$url + "/active_option", {
          userToken: userToken,
          askData: "lineList",
        })
        .then((response) => {
          this.activeOption = { lineChoices: response.data.res_record };
        });
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleSubmit(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          let form = new FormData();
          form.append("userToken", userToken);
          form.append("askData", "addItem");
          form.append("newItem", JSON.stringify(this.newItem));
          this.loadingStatus = true;
          let config = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          this.$axios
            .post(this.$url + this.post_url_tail, form, config)
            .then((response) => {
              this.loadingStatus = false;
              if (response.data.stat === "ok") {
                this.$Message.success(this.title + "添加成功！");
                this.update_count++;
                this.handleReset(name);
              } else {
                this.$Message.error(response.data.error_info);
              }
            })
            .catch((error) => {
              this.loadingStatus = false;
              this.$Message.error(error + "未知错误！");
            });
        } else {
          this.$Message.error("填入信息错误!");
        }
      });
    },
    modal_save(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          let form = new FormData();
          form.append("userToken", userToken);
          form.append("askData", "alterItem");
          form.append("currentItem", JSON.stringify(this.currentItem));
          this.loadingStatus = true;
          let config = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          this.$axios
            .post(this.$url + this.post_url_tail, form, config)
            .then((response) => {
              this.loadingStatus = false;
              if (response.data.stat === "ok") {
                this.$Message.success(this.title + "修改成功！");
                this.update_count++;
                this.scModal = false;
              } else {
                this.$Message.error(response.data.error_info);
              }
            })
            .catch((error) => {
              this.loadingStatus = false;
              this.$Message.error(error + "未知错误！");
            });
        } else {
          this.$Message.error("填入信息错误!");
        }
      });
    },
    modal_cancel() {
      this.scModal = false;
    },
    confirmBox(value) {
      this.$Modal.confirm({
        title: "确认删除该条" + this.title + "信息：",
        content:
          "<p>" +
          "所属线路：" +
          value.line_name +
          "</p>" +
          "<p>" +
          "应急值守点名称：" +
          value.name +
          "</p>",
        onOk: () => {
          this.delItem(value.id);
        },
        onCancel: () => {},
      });
    },
    delItem(id) {
      this.$axios
        .post(this.$url + this.post_url_tail, {
          userToken: userToken,
          askData: "delItem",
          pid: id,
        })
        .then((response) => {
          if (response.data.stat === "ok") {
            this.$Message.success("删除成功！");
            this.update_count++;
          }
        })
        .catch((error) => {
          this.$Message.warning(error + "网络错误！操作失败。");
        });
    },
    itemEdit(value) {
      this.currentItem = itemFac();
      this.currentItem = JSON.parse(JSON.stringify(value));
      this.scModal = true;
    },
  },
};
</script>
<style scoped>
.inner-wrapper {
  width: 100%;
  display: flex;
  display: -webkit-flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
  min-height: 700px;
}

.title-wrapper {
  text-align: center;
  font-size: 20px;
  padding: 16px;
}

.editor-wrapper,
.preview-wrapper {
  margin: 0px 20px 0px;
  width: 750px;
  font-size: 14px;
  text-align: center;
  padding: 10px 0;
}

.form-wrapper {
  text-align: left;
  border: 1px solid #eee;
  padding: 80px 120px 0px 50px;
  min-height: 600px;
  position: relative;
}

.sc-content-wrapper {
  padding: 0 50px;
  color: #333;
  font-size: 14px;
}
.choosed-product-wrapper {
  min-height: 100px;
  padding: 10px 20px;
  border: 1px solid #eee;
  box-shadow: 1px 1px 1px #eee;
  margin: 20px 0;
}

.sc-content-wrapper td:first-child {
  text-align: right;
  padding-right: 10px;
}

.sc-content-wrapper td {
  height: 45px;
  color: #777;
}

.sub-content-wrapper {
  margin: 10px 0;
  border: 1px solid #eee;
  position: relative;
}

.sub-content-title {
  position: absolute;
  left: 0;
  overflow: hidden;
  height: 100%;
  writing-mode: vertical-lr;
  text-align: center;
  background: #f5f5f5;
  letter-spacing: 10px;
  text-indent: 10px;
  padding: 12px 10px;
}

.table-wrapper {
  padding: 22.5px 20px;
  margin-left: 50px;
}

.fast-button {
  cursor: pointer;
}

input::-webkit-input-placeholder {
  color: #ccc;
  text-align: center;
}

input:-ms-input-placeholder {
  color: #ccc;
  text-align: center;
}

input::-moz-placeholder {
  color: #ccc;
  text-align: center;
}
tr.modal-product-choosed-tr {
  border-bottom: 1px solid #ddd;
}

tr.modal-product-choosed-tr:last-child {
  border: none;
}
</style>